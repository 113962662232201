
.dashboard-user-pages-content-bloc-bg {
    background: url(../../../../public/images/bg-gradient.png);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.du-main-page-img {
    height: 180px;
    position: absolute;
    right: 0;
    bottom: 0;
}