.du-quota-recap-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
}

.du-quota-recap-card {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    border-radius: 14px;
    border: 1px solid #DFDFDF;
    overflow: hidden;
}

.du-quota-recap-card-top {
    display: flex;
    width: 100%;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
}

.du-quota-recap-card-top-text {
    color: #1B1B1B;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.du-quota-recap-card-top-quota-box {
    display: flex;
    flex-direction: row;
    gap: 1px;
}

.du-quota-recap-card-top-quota-box-text {
    color: var(--dark-gray, #57575B);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.du-quota-recap-card-bottom {
    background: var(--light-purple, #E4E0F9);
    height: 10px;
    width: 100%;
}

.du-quota-recap-card-bottom-progress {
    background: var(--purple, #5C4EFA);
    width: 30%;
    height: 100%;
}