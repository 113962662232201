.upvote-promo-card {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    gap: 1rem;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 14px;
    box-shadow: 0px 0px 2px rgba(175, 178, 206, 0.56),
        0px 1px 4px rgba(4, 4, 52, 0.1);
    max-width: 350px;
    /* or whatever width suits your layout */
    height: 300px;
}

.upvote-promo-card-image {
    width: 40%;
    height: 100%;
    /* image takes ~40% of card width */
    border-radius: 8px;
    overflow: hidden;
}

.upvote-promo-card-image img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.upvote-promo-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 60%;
    max-height: 315px;
    /* text area is the other ~60% */
}

.upvote-promo-card-content h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 144% */
}

.upvote-promo-card-content p {
    color: #000;
    font-family: "Hind Madurai", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 166.667% */
}

.upvote-promo-button {
    margin-top: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    padding: 0.5rem 1rem;
    background: #5c4efa;
    /* your purple */
    color: #fff;
    border: none;
    border-radius: 50px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    cursor: pointer;
}

.upvote-promo-button .material-symbols-outlined {
    font-size: 18px;
}