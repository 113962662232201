.du-email-bloc-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.du-email-bloc-content-card {
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
border: 1px solid #DFDFDF;
}

.du-email-bloc-content-card-left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.du-email-bloc-content-card-icon-box {
    display: flex;
    padding: 5.577px;
    align-items: center;
    gap: 11.154px;
    border-radius: 5.577px;
background: var(--light-purple, #E4E0F9);
}

.du-email-bloc-content-card-icon-box i {
    font-size: 18px;
    color: #1B1B1B;
}

.du-email-bloc-content-card-text-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex: 1 0 0;
}

.du-email-bloc-content-card-title {
    color: #1B1B1B;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.du-email-bloc-content-card-text {
    color: var(--dark-gray, #57575B);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.du-email-bloc-content-card-right {

}

/* Toggle */
.du-toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.du-toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.du-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.du-slider::before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .du-slider {
    background-color: #5C4EFA;
}

input:checked + .du-slider::before {
    transform: translateX(20px);
}