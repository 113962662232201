.propose-feature-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.propose-feature-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.propose-feature-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.propose-feature-content h2 {
  margin-bottom: 0.5rem;
  color: #333;
}

.propose-feature-content p {
  color: #666;
  margin-bottom: 1.5rem;
}

.propose-feature-content label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
}

.propose-feature-content input[type="text"],
.propose-feature-content textarea,
.propose-feature-content select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.propose-feature-content textarea {
  min-height: 100px;
  resize: vertical;
}

.propose-feature-image-upload {
  margin: 1.5rem 0;
}

.propose-feature-image-upload p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.propose-feature-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cancel-button,
.submit-button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button {
  background: #f5f5f5;
  border: 1px solid #ddd;
  color: #333;
}

.submit-button {
  background: var(--purple, #5C4EFA);
  border: none;
  color: white;
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.error-text {
  color: #dc3545;
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

/* Category select styling */
.category-select {
  display: flex;
  align-items: center;
}

.category-select option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
}

.category-select option .material-symbols-outlined {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

/* Custom styling for the select dropdown */
.category-select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}