.du-project-max-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex
;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    color: var(--black, #131313);
    font-family: Montserrat;
}

.du-project-max-popup-content {
    display: flex;
    width: 640px;
    padding: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex-shrink: 0;
    border-radius: 17px;
    background: #FFF;
}

.du-project-max-popup-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.du-project-max-popup-top-title {
    color: #1B1B1B;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 125% */
    letter-spacing: -0.64px;
}

.du-project-max-popup-top-text {
    color: #1B1B1B;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.64px;
}

.du-project-max-popup-btn {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #1B1B1B;
    background: #5C4EFA;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    cursor: pointer;
}