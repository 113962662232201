/* A full-screen overlay */
.delete-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* dimmed backdrop */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  color: var(--black, #131313);
  font-family: Montserrat;
}

/* The white modal container */
.delete-popup-container {
  background: #fff;
  width: 480px;
  max-width: 90%;
  border-radius: 10px;
  position: relative;
  padding: 24px;
  box-shadow: 0px 0px 2px rgba(175, 178, 206, 0.56),
    0px 1px 4px rgba(4, 4, 52, 0.1);
  gap: 20px;
  display: flex;
  flex-direction: column;
}

/* Optional close button (X) in top-right */
.close-button {
  position: absolute;
  top: 14px;
  right: 14px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;
}

.delete-popup-overlay h2 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

/* The red box with alert text */
.red-warning-box {
  color: var(--white, #FFF);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 15px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: var(--red, #ED3B54);

}

.delete-description {
  color: var(--black, #131313);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  white-space: normal; /* Permet au texte de revenir à la ligne */
  word-break: break-word; /* Gère les longs mots */
  overflow-wrap: break-word; /* Gère les longs mots */
  margin: 0; /* Assurez-vous qu'il n'y a pas de marges inattendues */
}

.delete-popup-container label {
  color: var(--black, #131313);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Input for typing email */
.delete-popup-container input {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* The button row */
.delete-popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Cancel button */
.cancel-button {
  background-color: #ccc;
  color: #333;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

/* Delete (red) button */
.delete-button {
  background-color: #e53e3e;
  /* red */
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.delete-button:disabled {
  background-color: #f8d7da;
  color: #8a2c2c;
  cursor: not-allowed;
  opacity: 0.7;
}

.delete-input-and-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}