.du-profile-recap-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.du-profile-recap-btn-text {
    color: var(--purple, #5C4EFA);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    /* 14px */
}

.du-profile-recap-btn-icon {
    color: var(--purple, #5C4EFA);
    font-size: 14px;
}

.du-profile-recap-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* so top aligns */
    gap: 15px;
    position: relative;
    /* for the delete button if needed */
    align-self: stretch;

}

.du-profile-recap-content-left {
    display: flex;
    align-items: center;
}

.du-profile-recap-content-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.du-profile-recap-content-right-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.du-profile-recap-content-right-box-title {
    color: #1B1B1B;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 16px */
}

.du-profile-recap-content-right-box-text {
    color: var(--dark-gray, #57575B);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    /* 14px */
}
.du-profile-recap-content-right-box-text span{
    color: var(--dark-gray, #57575B);
    font-size: 14px;
}
/* Container is a perfect circle, 80x80, hides overflow. */
.du-profile-image-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    /* so user knows it's clickable */
    background-color: #eee;
    /* fallback if no image */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* The image takes 100% of container, covers it fully (zoom/crop). */
.du-profile-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* ensures image covers entire circle */
    display: block;
}

.du-profile-delete-account {
    width: 100%;
    display: flex;
    justify-content: flex-end
}

.du-profile-delete-account button {
    color: #ED3B54;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    /* 14px */
}
.du-profile-delete-account span {
    color: #ED3B54;
    font-size: 18px;
}

.dashboard-user-pages-content-bloc-top-label {
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 6px;
background: var(--purple, #5C4EFA);
}
.dashboard-user-pages-content-bloc-top-label i {
    color: var(--white, #FFF);
    font-size: 14px;
}
.dashboard-user-pages-content-bloc-top-label-text {
    color: var(--white, #FFF);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 12px */
}