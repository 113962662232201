/* Popup Container */
.popup {
  display: flex;
  position: fixed;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  justify-content: center;
  background-color: #1B1B1B;
}

/* Main Logo/Image inside the popup */
.popup-wallet-main-img {
  height: 70px;
  width: 400px;
  position: absolute;
  top: 5%;
}

/* Popup Content Container */
.popup-content {
  min-width: 640px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-content: center;
  border-radius: 10px;
  color: #1B1B1B;
  background-color: var(--white);
  gap: 30px;
}

.popup-content p {
  font-family: Inter;
  font-size: 14px;
}

.Sign-in-header {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Wallet Buttons List */
.wallet-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

/* Error Message */
.error-message {
  font-size: 14px;
  margin: 10px;
  display: flex;
  justify-content: center;
  color: #FF4747;
  text-align: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.64px;
}

/* Wallet Buttons */
.wallet-btn {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.64px;
  /* approx. 172% of 16px */

  display: flex;
  height: 49px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid rgba(203, 213, 225, 1);
  background-color: var(--white);

}

.wallet-btn img {
  width: 25px;
  border-radius: 100px;
}

/* Hover & Disabled States */
.wallet-btn:hover {
  box-shadow: 0px 0px 16.587px 0px rgba(191, 151, 255, 0.24) inset;
  transition: 0.3s ease;
  cursor: pointer;
}

.wallet-btn:disabled {
  background: #1A1D21;
  border: 1px solid #24292E;
}

.wallet-btn:disabled:hover {
  cursor: not-allowed;
  background: #1A1D21;
}

/* Typography for H1 in the Popup */
.popup-content h1 {
  color: #1B1B1B;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 125% */
  letter-spacing: -0.64px;
}


.popup-content h3 {
  color: #1B1B1B;
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 117.647% */
  letter-spacing: -0.64px;
}

.terms-links,
.sign-in-redirect {
  text-align: center;
}

/* Media Queries */
@media screen and (max-width: 800px) {
  .popup-content {
    width: 70%;
    margin: 0;
  }
}

@media screen and (max-width: 358px) {
  .popup-content {
    width: 90%;
    margin: 0;
  }
}


.media-loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.spinner {
  width: 32px;
  height: 32px;
  border: 4px solid #ccc;
  border-top: 4px solid #888;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 8px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.popup-content u,
.popup-content a {
  color: #1B1B1B;
  text-decoration: underline;
}