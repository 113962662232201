/* src/components/DashboardUser/CreateProjectPopup.css */
.create-project-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.create-project-popup-content {
  background: #fff;
  display: flex;
  width: 564px;
  padding: 30px 35px;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(175, 178, 206, 0.56), 0px 1px 4px 0px rgba(4, 4, 52, 0.10);
}

.create-project-popup-content h2 {
  color: var(--black, #131313);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.create-project-popup-content p {
  color: var(--black, #131313);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.create-project-popup-content input {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--Texte---diteur, #CBD5E1);
  width: 100%;
}

.create-project-popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cancel-button {
  background-color: #ccc;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
}

.create-project-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.create-project-popup-content {
  background: #fff;
  display: flex;
  width: 564px;
  padding: 30px 35px;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(175, 178, 206, 0.56),
    0px 1px 4px 0px rgba(4, 4, 52, 0.1);
}

.create-project-popup-content h2 {
  color: #131313;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
}

.create-project-popup-content p {
  color: #131313;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.create-project-popup-content input {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #cbd5e1;
  width: 100%;
}

.create-project-popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cancel-button {
  background-color: #ccc;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
}

.create-button {
  background-color: #6b46c1; /* Purple or brand color */
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
}

/* When disabled, change color & cursor */
.create-button:disabled {
  background-color: #aaa;  /* e.g. gray out */
  cursor: not-allowed;
  opacity: 0.7;
}
