@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&display=swap');

.feature-user-pages-content-bloc-bg {
    background: url(../../../../../public//images//bg-gradient.png);
    background-size: cover;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 25px;
    align-items: flex-start;
    gap: 25px;
    align-self: stretch;
    border-radius: 18px;
    box-shadow: 0px 0px 2px 0px rgba(175, 178, 206, 0.56), 0px 1px 4px 0px rgba(4, 4, 52, 0.10);
    position: relative;
    width: 70%;
    overflow: hidden;
}

.feature-user-pages-content {
    min-height: 100%;
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    background: #FFF;
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 35px;
}

.feature-upvote-hero {
    display: flex;
    align-items: flex-start;
    gap: 35px;
    align-self: stretch;
}

.feature-user-pages-content-h1 {
    color: var(--white, #FFF);
    font-family: Montserrat;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.feature-user-pages-content-p {
    color: #FFF;
    font-family: "Hind Madurai", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 80%;
}

.feature-upvote-hero-cta {
    display: flex;
    flex-direction: column;
height: 250px;
padding: 25px 30px;
align-items: flex-start;
gap: 15px;
flex: 1 0 0;
border-radius: 18px;
background: #FFF;
box-shadow: 0px 0px 2px 0px rgba(175, 178, 206, 0.56), 0px 1px 4px 0px rgba(4, 4, 52, 0.10);
justify-content: space-around;
}
.feature-upvote-hero-cta-top {
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 15px;
align-self: stretch;
}
.feature-upvote-hero-cta-title {
    font-family: Montserrat;
    color: #2B2B2B;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.feature-upvote-hero-cta-text {
    font-family: "Hind Madurai", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    color: #2B2B2B;

    /* 138.889% */
}

.feature-upvote-hero-cta-btn {
    display: flex;
    height: 42px;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 50px;
    border: none;
    background: var(--purple, #5C4EFA);
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* move to the right within the container, optional */
    cursor: pointer;

}
.feature-upvote-hero-cta-btn span {
    font-size: 18px;
}
