.du-project-bloc-content {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    overflow-x: auto; /* Ajoutez cette ligne pour permettre le défilement horizontal */
    white-space: nowrap; /* Empêche les éléments de passer à la ligne */
    width: 100%;
}

.du-project-bloc-card {
    display: flex;
    max-width: 300px;
    width: 100%;
    height: 180px;
    padding: 10px 10px 15px 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 0px 2px 0px rgba(175, 178, 206, 0.56), 0px 1px 4px 0px rgba(4, 4, 52, 0.10);    
    flex-shrink: 0; /* Empêche les cartes de rétrécir */
}

.du-project-bloc-content::-webkit-scrollbar {
    display: none; /* Pour Chrome, Safari et Opera */
}


.du-project-bloc-card-img {
    border-radius: 15px;
    height: 80%;
    width: 100%;
}

.du-project-bloc-card-bottom {
    display: flex;
    padding-left: 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.du-project-bloc-card-bottom-text {
    color: #1B1B1B;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */
}

.du-project-bloc-card-bottom span {
    color: rgba(6, 6, 18, 0.5);
    font-size: 16px;
    cursor: pointer;
}

/* No Project Card */
.du-project-bloc-card-no-project {
    display: flex;
    width: 300px;
    height: 180px;
    padding: 10px 10px 20px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 18px;
    background: var(--purple, #5C4EFA);
    box-shadow: 0px 0px 2px 0px rgba(175, 178, 206, 0.56), 0px 1px 4px 0px rgba(4, 4, 52, 0.10);
    cursor: pointer;
}

.du-project-bloc-card-no-project i {
    font-size: 30px;
    color: rgba(255, 255, 255, 1);
}

.du-project-bloc-card-text-white {
    color: var(--white, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 18px */
}

.popup-menu {
    position: absolute;
    bottom: 25%; /* Position above the icon */
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    font-family: Montserrat;
}

.popup-menu button {
    display: flex;
    background: none;
    border: none;
    padding: 4px 8px;
    width: 100%;
    text-align: right;
    cursor: pointer;
    align-items: center;
    gap: 10px;
}

.popup-menu button:hover {
    background-color: #f0f0f0;
}

.popup-menu span {
    color: red;
}
