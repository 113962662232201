.du-leftbar-wrapper {
    width: 70px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 35px 0px;
}

.du-leftbar-logo {
    width: 40px;
    height: auto;
}

.du-leftbar-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.du-leftbar-content-item {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.du-leftbar-content-item.active {
    border-left: 4px solid #FFF;
    color: var(--white);
    font-size: 23px;
}