/* ALL FEATURES SECTION */
.all-features-band {
  padding: 20px;
  height: calc(100vh - 300px); /* Adjust based on your header height */
  overflow-y: auto;
  border-radius: 18px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(175, 178, 206, 0.56),
    0px 1px 4px 0px rgba(4, 4, 52, 0.1);
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 1.5rem;
  /* spacing below if needed */
}

/* Header with a title, subtitle, search box, etc. */
.all-features-header {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.all-features-header-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.all-features-header h2 {
  color: #1B1B1B;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.all-features-header p {
  color: var(--dark-gray, #57575B);
  font-family: "Hind Madurai", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

/* Optional toolbar with a search box and sort dropdown */
.all-features-toolbar {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.all-features-toolbar-search {
  display: flex;
  width: 226px;
  height: 100%;
  padding: 0px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
  color: var(--dark-gray, #57575B);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.all-features-toolbar-search i {
  color: var(--dark-gray, #57575B);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.all-features-toolbar-search input[type="text"] {
  border: none;
}

.all-features-toolbar-search input::placeholder {
  color: rgba(87, 87, 91, 0.30);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 14px */
}

.all-features-toolbar .sort-dropdown {
  display: flex;
  padding: 3px 8px;
  align-items: center;
  color: var(--dark-gray, #57575B);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  gap: 4px;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
}

.all-features-toolbar .sort-dropdown select {
  border: none;
  padding: 6px 8px 6px 5px;
  border-radius: 6px;
  padding: 0.4rem 0.75rem;
  color: #57575B;
  text-align: left;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.sort-dropdown label {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
}

.sort-dropdown label span {
  font-size: 17px;

}

/* The actual grid of features: EXACTLY 3 columns */
.all-features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px 0;
}

/* Make it responsive for smaller screens */
@media (max-width: 1200px) {
  .all-features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .all-features-grid {
    grid-template-columns: 1fr;
  }
}

/* Each feature card in the grid */
.feature-card {
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* RANK (#1, #2, etc.) in the top-right corner */
.feature-card .feature-rank {
  position: absolute;
  top: 10px;
  right: 15px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: var(--dark-gray, #57575B);
  text-align: right;
}

/* The icon block on the left side of each card */
.feature-card-icon {
  width: 48px;
  height: 48px;
  background: var(--purple, #5C4EFA);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-card-icon .material-symbols-outlined {
  font-size: 24px;
  color: #fff;
}

/* The middle text area for title, description, etc. */
.feature-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.feature-card-content h3 {
  color: #1B1B1B;
  text-align: left;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.feature-card-content p {
  color: var(--dark-gray, #57575B);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: left;
  margin-bottom: 2vh;
}


/* The bottom-right vote container (green number, up arrow, down arrow) */
.feature-card-votes {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.vote-count-green {
  color: #22C55E;
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.vote-button-up,
.vote-button-down {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  transition: all 0.2s ease;
}

.vote-button-up:hover,
.vote-button-down:hover {
  transform: scale(1.1);
}

.vote-button-up.voted i,
.vote-button-down.voted i {
  transform: scale(1.1);
}

.vote-button-up.voted:hover,
.vote-button-down.voted:hover {
  opacity: 0.8;
}

/* The overlay that darkens the background */
.image-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  /* semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* The container for the image and close button */
.image-preview-content {
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* The displayed image */
.image-preview-content img {
  max-width: 100%;
  max-height: 80vh;
  /* ensure it doesn't overflow */
  margin-bottom: 1rem;
}

/* The close button in the modal */
.image-preview-content .close-button {
  border: none;
  background: #eee;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* The new "View attached file" button can share .feature-attachment-link styling */
.feature-attachment-link {
  border: none;
  background: none;
  cursor: pointer;
  color: var(--dark-gray, #57575B);
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  /* space between icon and text */
  text-decoration: none;
}