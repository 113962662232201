.wallet-types-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .wallet-box {
    border-radius: 8px;
    border: 0.6px solid var(--Secondary-Colors-Color-4, #343B4F);
    background: var(--Secondary-Colors-Color-1, #0B1739);
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    position: relative;
  }
  
  .wallet-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh;
    gap: 2vh;
  }
  
  .wallettypes-summary-title {
    color: #AEB9E1;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
  

  .wallet-count {
    color: var(--Neutral-Colors-100, #FFF);
    font-family: "DM Sans";
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
  }
  