/* src/components/SignUp.css */
.signup-popup-container {
    padding: 2rem;
    border-radius: 10px;
    background-color: #fff;
    max-width: 400px;
    margin: 0 auto;
}

.signup-popup-container h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.signup-description {
    font-size: 1rem;
    color: #777;
    margin-bottom: 1.5rem;
}

.signup-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.signup-form label {
    margin: 0.5rem 0 0.25rem;
    color: #1B1B1B;
    font-family: Montserrat;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 117.647% */
    letter-spacing: -0.64px;

}

.signup-form input {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.signup-divider {
    width: 50%;
    display: inline-block;
    margin: 1rem 0;
    border-bottom: 1px solid #1B1B1B;
}

.signup-or-text {
    display: inline-block;
    margin: 0 0.5rem;
    font-family: Inter;
}

.wallet-instructions {
    margin: 0.5rem 0;
    font-size: 0.9rem;
}

.wallet-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
    justify-content: center;
}

.wallet-buttons img {
    max-width: 25px;
}

.wallet-option {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid var(--Texte---diteur, #CBD5E1);
}

.wallet-option.selected {
    background-color: #eee;
    border-color: #aaa;
}

.create-account-btn {
    display: flex;
    padding: 13px 260px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #1B1B1B;
    background: #5C4EFA;

    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.error-text {
    color: red;
    margin-top: 0.5rem;
}

.signup-terms {
    font-size: 0.85rem;
    margin-top: 1rem;
    color: #777;
}

.already-have-account {
    margin-top: 1rem;
    font-size: 0.9rem;
}

.wallet-option {
    /* existing button styles */
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    transition: background-color 0.2s;
  }
  
  /* When the wallet is selected but not yet signed */
  .wallet-option.selected {
    border-color: #999;
    background-color: #ececec;
  }
  
  /* When the wallet is fully signed/connected */
  .wallet-option.connected {
    border-color: #4caf50; /* green border */
    background-color: #dff0d8; /* light green background */
    color: #2f6627;
    font-weight: bold;
  }
  
  .create-account-btn:disabled {
    border-radius: 10px;
    border: 1px solid #1B1B1B;
    background: #1B1B1B;
    cursor: not-allowed;
  }
  
  