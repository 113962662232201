/********************************************
 *  Discord-Specific Overrides & Additions
 ********************************************/

/* 
 * Main wrapper that has the background image,
 * border-radius, etc.
 */
.dashboard-user-pages-content-bloc.du-bloc-content {
  padding: 0;
  box-shadow: none;
  border-radius: 18px;

  background: url("https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageDashboard%2FDashboard%20Redo%2FbackgroundDiscordSection.png?alt=media&token=ab452581-a216-4be5-a148-c5010543b772");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
}

/*
 * Inner card that holds the text and button.
 * Transparent so parent’s image shows through.
 */
.du-bloc-content-card.du-discord-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  color: #FFFFFF;
  border: none;
}

/* Title styling for the Discord card */
.du-discord-title {
  color: #FFF;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  margin: 0;
}

/* Subtitle styling for the Discord card */
.du-discord-subtitle {
  color: #FFF;
  font-family: "Hind Madurai", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 4px;
  margin-bottom: 0;
}

/* "Join Now" button styling */
.du-discord-button {
  display: flex;
  width: 163px;
  height: 35px;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--purple, #5C4EFA);
  color: #FFF;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.du-discord-button:hover {
  background-color: #4A3EE7;
}

/********************************************
 *  (Optional) Discord Icon Styling
 ********************************************/
.du-discord-icon {
  background: #5C4EFA;
  /* Could match button color or be lighter/darker */
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.du-discord-icon i {
  font-size: 18px;
  color: #FFFFFF;
}

/********************************************
 * Carousel Layout
 ********************************************/
.carousel-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: 20px;
}

/* Right panel with Prev/Next, slide monitor, dots */
.carousel-right-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  margin-right: 20px;
  /* space between card and right panel */
}

/********************************************
 *  Carousel Dots
 ********************************************/
.carousel-dots {
  display: flex;
  width: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 50%;
  transition: background-color 0.3s;
}

.dot.active {
  height: 16px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--light-purple, #E4E0F9);
}