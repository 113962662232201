.dashboard-user-wrapper {
    display: flex;
    flex-direction: row;
    background: #1B1B1B;
    height: 100vh; 
    width: 100vw;
}

.dashboard-user-pages-wrapper {
    background: #1B1B1B;
    padding: 15px 15px 15px 0px;
    height: 100vh; /* Utilisez 100vh pour s'assurer que la hauteur est basée sur la fenêtre d'affichage */
    width: 100%;
    overflow: hidden;
}

.dashboard-user-pages-content {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    overflow: auto;
    background: #FFF;
    padding: 35px;
    box-sizing: border-box; /* Ajoutez ceci pour inclure le padding dans la hauteur totale */
}

.dashboard-user-pages-content-wrapper {
    overflow-y: auto; /* Utilisez auto pour afficher la barre de défilement uniquement si nécessaire */
    display: flex;
    flex-direction: row;
    gap: 35px;
}

.dashboard-user-pages-content-left {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.dashboard-user-pages-content-right {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.dashboard-user-pages-content-row {
    display: flex;
    flex-direction: row;
    gap: 35px;
    width: 100%;
}

.dashboard-user-pages-content-bloc {
    display: flex;
    padding: 20px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    align-self: stretch;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 0px 2px 0px rgba(175, 178, 206, 0.56), 0px 1px 4px 0px rgba(4, 4, 52, 0.10);
    position: relative;
    overflow: hidden;
}


.dashboard-user-pages-content-h1 {
    color: var(--white, #FFF);
    font-family: Montserrat;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 80%;
}

.dashboard-user-pages-content-p {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
}

.dashboard-user-pages-content-bloc-top-title {
    color: #1B1B1B;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 20px */
}

.dashboard-user-pages-content-bloc-top-text {
    color: var(--dark-gray, #57575B);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    /* 14px */
}

.dashboard-user-pages-content-bloc-top-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.dashboard-user-pages-content-bloc-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.dashboard-user-pages-content-bloc-top-button-box {
    display: flex;
    align-items: center;
    gap: 15px;
}

.dashboard-user-pages-content-bloc-top-button-purple {
    display: flex;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 9px;
    background: var(--purple, #5C4EFA);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    color: var(--white, #FFF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 14px */
    height: 28px;
    cursor: pointer;
}

.dashboard-user-pages-content-bloc-top-button-purple-round {
    display: flex;

    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 50px;
    background: var(--purple, #5C4EFA);
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dashboard-user-pages-content-bloc-top-button-arrow-box {
    display: flex;
    height: 28px;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
}

.dashboard-user-pages-content-bloc-top-button-arrow-box a {
    color: rgba(87, 87, 91, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.dashboard-user-pages-content-bloc-edit-btn-save {
    display: flex;
width: 75px;
padding: 7px 15px;
justify-content: center;
align-items: center;
gap: 5px;
border-radius: 9px;
background: #22C55E;
box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
color: var(--white, #FFF);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 12px */
cursor: pointer;
}

.du-height-200 {
    height: 200px;
}