@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
:root {
  /*========== Color ==========*/
  --menu-bg: #030014;
  --not-selected: #64748b;
  --main-color: #5C4EFA;
  --purple: #5C4EFA;
  --purple-hover: #6f02b8;
  --text-editor: #cbd5e1;
  --item-selected: #217bf4;
  --dark-grey: #334155;
  --white: #f9f9f9;
  --deep-purple: #421660;
  --body-purple: #030014;
  --green: #10843A;

  /*========== Font family ==========*/
  --inter: "Inter", sans-serif;
  --montserrat: "Montserrat", sans-serif;
  --dmsans: "DM Sans", sans-serif;
}

/*==================== BASE ====================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
input:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  background-color: var(--body-purple);
  overflow-x: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.section {
  padding: 80px 10%;
}
.section-title {
  color:#B6B2FF;
  text-align: center;
  font-family: "DM Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.section-text {
  color: #817EB5;
text-align: center;
font-family: "DM Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
max-width: 500px;

}
.section-label {
  display: flex;
  padding: 5px 13.75px 7px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 32px;
border: 1px solid #4D2F8C;
background: rgba(255, 255, 255, 0.00);
box-shadow: 0px -7px 11px 0px rgba(164, 143, 255, 0.12) inset;
color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}
.section-label img {
  width: 18px;
}
.purple-btn {
  border-radius: 11.058px;
  background: linear-gradient(180deg, rgba(60, 8, 126, 0.00) 0%, rgba(60, 8, 126, 0.32) 100%), rgba(113, 47, 255, 0.12);
  box-shadow: 0px 0px 16.587px 0px rgba(191, 151, 255, 0.24) inset;
  border: 1.382px solid #4D2F8C;
  display: flex;
  padding: 9.676px 22.337px 12.44px 22.116px;
  align-items: flex-start;
  color: #F4F0FF;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.645px; /* 142.857% */

}
.purple-btn:hover {
  background: linear-gradient(180deg, rgba(60, 8, 126, 0.00) 0%, rgb(118 79 167 / 32%) 100%), rgba(113, 47, 255, 0.12);
  transition: 0.3s ease;
  cursor: pointer;
}
.grey-btn {
  border-radius: 11.058px;
  background: linear-gradient(180deg, rgba(115, 115, 115, 0.00) 0%, rgba(136, 133, 139, 0.32) 100%), rgba(241, 241, 241, 0.12);
  box-shadow: 0px 0px 16.587px 0px rgba(191, 151, 255, 0.24) inset;  border: 1.382px solid #4D2F8C;
  border: 1.382px solid #676767;
  display: flex;
  padding: 9.676px 22.337px 12.44px 22.116px;
  align-items: flex-start;
  color: #F4F0FF;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.645px; /* 142.857% */

}
.grey-btn:hover {
  background: linear-gradient(180deg, rgba(115, 115, 115, 0.00) 0%, rgba(136, 133, 139, 0.62) 100%), rgba(241, 241, 241, 0.12);
  transition: 0.3s ease;
  cursor: pointer;
}
/* .purple-light-btn {
  display: flex;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 28px;
  background: rgba(150, 0, 250, 0.4);
  gap: 10px;
  border: none;
}
.purple-light-btn:hover {
  cursor: pointer;
} */
.cta-icon {
  width: 27px;
}

@media only screen and (max-width: 850px) {
  .section {
    padding: 40px 5%;
  }
  .section__text {
    text-align: center;
    font-size: 16px;
  }
  .section-label,
  .purple-btn,
  .purple-light-btn {
    font-size: 16px;
  }
  .section-title {
    font-size: 5vw;
  }
}

/*==================== REUSABLE ====================*/
.bold {
  font-weight: 800;
}

/* In your CSS file */
.hover-cancel * {
  pointer-events: none;
}



/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c7c7c7;
  border-radius: 11.058px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 11.058px;
  background: linear-gradient(180deg, rgba(28, 4, 59, 0) 0%, rgba(60, 8, 126, 0.52) 100%), rgba(33, 14, 75, 0.52);
  box-shadow: 0px 0px 16.587px 0px rgba(191, 151, 255, 0.24) inset;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, rgba(60, 8, 126, 0.00) 0%, rgb(118 79 167 / 52%) 100%), rgba(113, 47, 255, 0.52);
  transition: 0.3s ease;
  cursor: pointer;}